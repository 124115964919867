import Swal from "sweetalert2";
import { DangerRight } from "../component/api/toastServices";
import i18n from '../i18n';  // Import i18n

export const warning = (confirm) => {
  return Swal.fire({
    title: i18n.t('warning.title'),
    text: i18n.t('warning.text'),
    iconHtml: '<i class="ri-alert-line"></i>',
    showCancelButton: true,
    confirmButtonText: i18n.t('all.confirm'),
    cancelButtonText: i18n.t('all.cancel'),
    customClass: {
      confirmButton: "btn bg-second text-light m15-right",
      cancelButton: "btn bg-darkGray text-light",
    },
    buttonsStyling: false,
  });
};
