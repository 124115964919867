import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"; // Import useTranslation


const Title = (props) => {
  const navigate = useNavigate()
  const { name,display,bottom } = props;
  const {t} = useTranslation(); // Initialize useTranslation

  const handleDashboardClick = () => {
    navigate("/admin/adminDashboard");
  };
  return (
    <div className='mainTitle d-flex align-items-center justify-content-between cursor-pointer' style={{marginBottom : bottom}}>
      <div className="title text-capitalized fw-600 " style={{color : "#686B71",fontSize : "20px"}}>{name}</div>
      <div className="titlePath" style={{display : display}}>
        <span  onClick={handleDashboardClick}>{t('dashboard.title')}  <i className="ri-arrow-right-s-line"></i></span>
        <span className='text-second'> {name}</span>
      </div>
    </div>
  );
}

export default Title;
