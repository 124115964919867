import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import i18n from "../../i18n";

const initialState = {
  expertReviews: [],
  isLoading: false,
  isSkeleton: false,
  total: 0,
};

// Fetch all expert reviews
export const getAllExpertReviews = createAsyncThunk(
  "admin/expertReview/getAll",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/expertReview/getAll?start=${payload.start}&limit=${payload.limit}&search=${payload?.search}`
    );
  }
);

// Delete an expert review
export const deleteExpertReview = createAsyncThunk(
  "admin/expertReview/delete",
  async (id) => {
    return apiInstance.delete(`admin/expertReview/delete?reviewId=${id}`);
  }
);

const expertReviewSlice = createSlice({
  name: "expertReviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllExpertReviews.pending, (state) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllExpertReviews.fulfilled, (state, action) => {
      state.expertReviews = action.payload.reviews;
      state.total = action.payload.total;
      state.isSkeleton = false;
    });

    builder.addCase(getAllExpertReviews.rejected, (state) => {
      state.isSkeleton = false;
    });

    builder.addCase(deleteExpertReview.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteExpertReview.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.expertReviews = state.expertReviews.filter(
          (review) => review._id !== action.meta.arg
        );
        Success(i18n.t("review.delete_success"));
      }
      state.isLoading = false;
    });

    builder.addCase(deleteExpertReview.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default expertReviewSlice.reducer;
