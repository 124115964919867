import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "../../i18n"; // Import i18n

export function Success(msg) {
  toast.success(
    <p className="text-dark tx-16 mb-0">
      {i18n.t('all.success')}: {msg}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function Secondary(msg) {
  toast.error(
    <p className=" tx-16 mb-0">{i18n.t('all.oops')}! {msg}</p>,
    {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function LeftNotifier(msg) {
  toast.warn(
    <p className=" tx-16 mb-0">{i18n.t('all.warning')}: {msg}</p>,
    {
      position: toast.POSITION.TOP_LEFT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function CenterInfo(msg) {
  toast.info(<p className=" tx-16 mb-0">{i18n.t('all.info')}: {msg}</p>, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: false,
    autoClose: 2000,
    theme: "light",
  });
}

export const CenterDanger = (msg) => {
  toast.error(<p className=" tx-16 mb-0">{i18n.t('all.error')}: {msg}</p>, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: false,
    autoClose: 2000,
    theme: "light",
  });
};

export function CenterWarning(msg) {
  toast.warn(<p className=" tx-16 mb-0">{msg}</p>, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: false,
    autoClose: 2000,
    theme: "light",
  });
}

// Side Alerts Notifications

export function SuccessLeft(msg) {
  toast.success(
    <p className=" tx-16 mb-0">
      <h3>{i18n.t('all.notice')}!</h3>{msg}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function WarningLeft(msg) {
  toast.warn(
    <p className=" tx-16 mb-0">
      <h3>{i18n.t('all.warning')}!</h3>{msg}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function DangerRight(msg) {
  toast.error(
    <p className=" tx-16 mb-0">
      {msg}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

// Gradient Side Alerts Notifications

export function GradientSuccess(msg) {
  toast.success(
    <p className=" tx-16 mb-0">
      <h3>{i18n.t('all.error')}!</h3>{i18n.t('all.file_missing')}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function GradientWarning(msg) {
  toast.warn(
    <p className=" tx-16 mb-0">
      {msg}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}

export function GradientDanger() {
  toast.error(
    <p className=" tx-16 mb-0">
      <h3>{i18n.t('all.error')}!</h3>{i18n.t('all.file_missing')}
    </p>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      autoClose: 2000,
      theme: "light",
    }
  );
}
