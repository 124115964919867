// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n
  .use(LanguageDetector) // Detects the user's language preference
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // Default language if no preference is detected
    detection: {
      // Override detection options to always start with 'en'
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng', // local storage key for language
    },
    debug: true, // Set to true for debugging language detection issues
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
