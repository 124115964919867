// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import usFlag from '../assets/flags/usa.png'; // Correct flag image
import saFlag from '../assets/flags/sa.png'; // Correct flag image

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en'; // Fallback to 'en' if undefined

  const changeLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="language-switcher">
      <button onClick={changeLanguage} className="btn btn-light d-flex align-items-center">
        <img
          src={currentLanguage === 'en' ? usFlag : saFlag}
          alt="Flag"
          style={{ width: '30px', height: '24px', paddingBottom: '4px' }}
        />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
