import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import i18n from '../../i18n'; // Import i18n for translations
import { DangerRight, Success } from "../../component/api/toastServices";

// Initial state
const initialState = {
  complain: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
};

// Async thunks
export const getComplains = createAsyncThunk(
  'user/complain/getComplains',
  async (payload) => {
    return apiInstanceFetch.get(
      `user/complain/getComplains?type=${payload?.type}&start=${payload?.start}&limit=${payload?.limit}&person=${payload?.person}`
    );
  }
);

export const solveComplain = createAsyncThunk(
  'user/complain/solveComplain',
  async (id) => {
    return apiInstanceFetch.put(`user/complain/solveComplain?id=${id}`);
  }
);

// Slice
const complainSlice = createSlice({
  name: "complainSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle getComplains actions
    builder.addCase(getComplains.pending, (state) => {
      state.isSkeleton = true;
    });

    builder.addCase(getComplains.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.complain = action.payload.data;
      state.isSkeleton = false;
    });

    builder.addCase(getComplains.rejected, (state) => {
      state.isSkeleton = false;
    });

    // Handle solveComplain actions
    builder.addCase(solveComplain.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(solveComplain.fulfilled, (state, action) => {
      if (action.payload.status) {
        const updated = action?.payload?.complain;
        const complainIndex = state?.complain?.findIndex(
          (complain) => complain?._id === updated?._id
        );
        if (complainIndex !== -1) {
          state.complain[complainIndex] = { ...state.complain[complainIndex], ...updated };
        }
        Success(i18n.t('complain.solve_success')); // Translated message
      }
      state.isLoading = false;
    });

    builder.addCase(solveComplain.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default complainSlice.reducer;
