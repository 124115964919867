import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExInput } from "../../extras/Input";
import Button from "../../extras/Button";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import LanguageSwitcher from '../../LanguageSwitcher'; // Import the LanguageSwitcher component
import { useTranslation } from "react-i18next"; // Import useTranslation

const CompleteBookingDetails = () => {
    const { dialogueData } = useSelector((state) => state.dialogue);
    const dispatch = useDispatch();
    const [mongoId, setMongoId] = useState("");
    const { t } = useTranslation(); // Initialize useTranslation

    console.log('dialogueData', dialogueData)
    useEffect(() => {
        if (dialogueData) {
            setMongoId(dialogueData);
        }
    }, [dialogueData]);

    return (
        <div className="dialog">
            <div class="w-100">
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-md-4 col-11">
                        <div class="mainDiaogBox">
                            <div class="row justify-content-between align-items-center formHead">
                                <div className="col-8">
                                    <h4 className="text-theme m0">{t('all.bookinginfo')}</h4>
                                </div>
                                <div className="col-4">
                                    <div
                                        className="closeButton"
                                        onClick={() => {
                                            dispatch(closeDialog());
                                        }}
                                    >
                                        <i className="ri-close-line"></i>
                                    </div>
                                </div>
                            </div>
                            <form id="expertForm">
                                <div className="row align-items-start formBody">
                                    <div className="col-12">
                                        <ExInput
                                            type={`text`}
                                            id={`reason`}
                                            name={`reason`}
                                            label={t('all.checkTime')}
                                            placeholder={t('all.Reason')}
                                            value={mongoId?.checkInTime}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <ExInput
                                            type={`text`}
                                            id={`Date`}
                                            name={`Date`}
                                            label={t('all.checkoutTime')}
                                            placeholder={t('all.Date')}
                                            value={mongoId?.checkOutTime}
                                            disabled={true}
                                        />
                                    </div>

                                </div>
                                <div className="row  formFooter">
                                    <div className="col-12 text-end m0">
                                        <Button
                                            className={`bg-gray text-light`}
                                            text={t('all.cancel')}
                                            type={`button`}
                                            onClick={() => dispatch(closeDialog())}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CompleteBookingDetails;
